import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '@components/pages/PageContainer';
import PagePanel from "@components/pages/PagePanel";
import { MenuContext } from '@contexts/MenuContext';
import { FlexRow } from '@components/layout/FlexStyles';

const views = [
  { id: "topDrugs", label: "Drugs Ranked", image: "/img/dashboardExamples/placeholder.svg" },
  { id: "topIssues", label: "Issues Ranked", image: "/img/dashboardExamples/placeholder.svg" },
  { id: "age", label: "Year by Age", image: "/img/dashboardExamples/histogram_year_by_age.png" },
  { id: "seriousness", label: "Year by Seriousness", image: "/img/dashboardExamples/histogram_year_by_seriousness.png" },
  { id: "sex", label: "Year by Sex", image: "/img/dashboardExamples/histogram_year_by_sex.png" },
  { id: "type", label: "Year by Type", image: "/img/dashboardExamples/histogram_year_by_type.png" }
]

const debug = true;
// Show the main page of tiles for the site (linked to the logo)
const PgDashboard = () => {
  let { id } = useParams<{ id: string }>();
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  const navigate = useNavigate();

  if (id === undefined) id = views[0].id;
  if (activeMenuId !== 'dashboard') setActiveMenuId('dashboard');

  debug && console.log("PgDashboard | id:", id);
  const handleTabClick = (tabId:string) => {
    navigate(`/dashboard/${tabId}`);
  }

  const getGraph = (tabId: string) => {
    const selected = views.find((v) => v.id === tabId);
    return(
      <img src={selected.image} alt={selected.label} width={1000}/>
    )
  }

  return (
    <PageContainer title='FAERS Data Overview'>
      <TabsPanel>
        { views.map((v) =>
          <Tab
            onClick = {() => handleTabClick(v.id)}
            activeTab = {v.id === id}
          >
            {v.label}
          </Tab>
        )}
      </TabsPanel>
      <PagePanel title={''}>
        <FlexRow h_centered>
            { getGraph(id) }
        </FlexRow>
      </PagePanel>
    </PageContainer>
  );
};

export default PgDashboard;

const TabsPanel = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  justify-content: center;
`;

const Tab = styled.div<{activeTab: boolean}>`
  display: flex;
  font-size: 14px;
  padding: 5px;
  min-width: 175px;
  margin-left: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.activeTab ? p.theme.palette.textPrimary
          :p.theme.palette.textSecondary};
  background-color: ${(p) => p.activeTab ? p.theme.palette.accentPrimary
          : p.theme.palette.backgroundQuaternary};
  :hover {
    color: ${(p) => p.theme.palette.textPrimary};
    background-color: ${(p) => p.theme.palette.accentSecondary};
  }
`;


