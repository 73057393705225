import React from "react";
import PageContainer from "@components/pages/PageContainer";
import {PageWideScrollBox} from "@components/pages/page.layout";
import AccountDetails from "../as_core/account/AccountDetails";
import {TextRegular} from "@components/elements/TextStyles";

const PgUserAccount = () => {

  return (
    <PageContainer title="User Account Information"  >
      <AccountDetails />
    </PageContainer>
  );
};
export default PgUserAccount;


