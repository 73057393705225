import React from 'react';
import styled from 'styled-components/macro';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

interface PropsI {
  id: string;
  question: string;
  response: string;
}

const FaqItem = (props:PropsI) => {
  const paragraphs = props.response.split('\n');
  return(
    <StyledAccordion id={`accordionSummary_${props.id}`}>
      <StyledAccordionSummary
        id = {`accordionSummary_${props.id}`}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      >{props.question}</StyledAccordionSummary>
      <StyledAccordionDetails id = {`accordionDetails_${props.id}`}>
        {paragraphs.map((p) => (<Paragraph>{p}</Paragraph>))}
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

export default FaqItem;

const StyledAccordion = styled(Accordion)`
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  &.MuiAccordion-root {
    font-size: 14px;
    line-height: 18px;
    padding-left: 20px;
    margin: 0;
    background-color: ${(p) => p.theme.palette.backgroundPrimary};
  }
  &.MuiAccordion-gutters {
    margin: 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  flex-direction: row-reverse;

  &.MuiAccordionSummary-root {
    margin: 0;
    padding: 0;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    padding: 0;
    color: ${(p) => p.theme.palette.accentPrimary};
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    font-size: 12px;
    line-height: 16px;
    margin-left: 30px;
    padding: 0;
  }
`;

const Paragraph = styled.div`
  margin-bottom: 14px;
`;