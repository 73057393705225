import React, { useContext } from 'react';
import PageContainer from '../components/pages/PageContainer';
import { MenuContext } from '../contexts/MenuContext';
import { FlexColumn, FlexItem } from '@components/layout/FlexStyles';
import Info from '@components/pages/infoFaq/Info';
import Faq from '@components/pages/infoFaq/Faq';

const PgInfo = () => {
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  if (activeMenuId !== 'info') setActiveMenuId('info');

  return (
    <PageContainer title='Info/FAQ'>
      <FlexColumn>
        <FlexItem>
          <Info />
        </FlexItem>
        <FlexItem>
          <Faq />
        </FlexItem>
      </FlexColumn>
    </PageContainer>
  );
};

export default PgInfo;

