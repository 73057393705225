import { AxiosRequestConfig } from 'axios';
import { User } from './api/';
import { handleError } from '@utils/misc';

export interface UserT {}

interface UserParamsT {}

export interface ResponseT {
  errors: Array<string>;
  data: Array<any>;
}

const useUserInfo = () => {

  const getUserInfo = async (token, params: UserParamsT): Promise<ResponseT> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await User.get(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    // if (resp.data.errors.length > 0) {
    //   console.log('getUser ERRORS', resp.data.errors);
    // }
    return resp;
  };

  return {
    getUserInfo,
  };
};
export default useUserInfo;
