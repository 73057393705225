import { FaersData } from './api/';
import { handleError } from '@utils/misc';

export interface ResponseT {
  errors: Array<string>;
  info: {[key: string]: any}
  data?: Array<any>;
}

const useFaersData = () => {

  const getInfo = async (token): Promise<ResponseT> => {
    let response;

    try {
      response = await FaersData.info(token);
    } catch (err) {
      handleError(err);
      return null;
    }

    return response;
  };

  const getTableData = async (token, table, limit): Promise<ResponseT> => {
    let response;

    try {
      response = await FaersData.select(token, {
        params: { "limit": limit, "table": table }
      });
    } catch (err) {
      handleError(err);
      return null;
    }

    return response;
  };

  return {
    getInfo,
    getTableData
  };
};

export default useFaersData;
