import React from "react";

const Icon3RnDLogo = ({ size = 24 }) => {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 28 28" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="87.5%" y1="0%" x2="54.7370284%" y2="80.7915632%" id="linearGradient-1">
            <stop stopColor="#CD1157" offset="0%"></stop>
            <stop stopColor="#D42868" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="50.1247806%" y1="78.7421341%" x2="87.5%" y2="0%" id="linearGradient-2">
            <stop stopColor="#FCC4E4" offset="0%"></stop>
            <stop stopColor="#F6B0D9" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-3">
            <stop stopColor="#E878AB" offset="0%"></stop>
            <stop stopColor="#F38DBB" offset="100%"></stop>
          </linearGradient>
          <polygon id="path-4" points="0.073747917 6.13543403e-05 12.292158 6.13543403e-05 12.292158 15.0318134 0.073747917 15.0318134"></polygon>
        </defs>
        <g id="Group" transform="translate(0.000000, 0.000000)">
          <polygon id="Path" fill="#F6B0D9" points="12.3014972 28.4090909 12.3014972 14.2045455 0 21.3068182"></polygon>
          <polygon
            id="Path"
            fill="url(#linearGradient-1)"
            points="24.6026336 21.3068182 24.6026336
            7.10227273 12.3011364 14.2045455"
          ></polygon>
          <polygon
            id="Path"
            fill="#CD1157"
            transform="translate(18.451885, 21.306818) scale(-1, 1)
            translate(-18.451885, -21.306818) "
            points="24.6026336 28.4090909 24.6026336 14.2045455 12.3011364
            21.3068182"
          ></polygon>
          <polygon
            id="Path"
            fill="url(#linearGradient-2)"
            transform="translate(6.150749, 14.204545)
            scale(-1, 1) translate(-6.150749, -14.204545) "
            points="12.3014972 21.3068182 12.3014972
            7.10227273 6.45947942e-15 14.2045455"
          ></polygon>
          <polygon
            id="Path"
            fill="#E878AB"
            points="12.3014972 14.2045455 12.3014972 0 6.45947942e-15
            7.10227273"
          ></polygon>
          <polygon
            id="Path"
            fill="url(#linearGradient-3)"
            transform="translate(18.451885, 7.102273)
            scale(-1, 1) translate(-18.451885, -7.102273) "
            points="24.6026336 14.2045455 24.6026336
            0 12.3011364 7.10227273"
          ></polygon>
        </g>
      </svg>
    </>
  );
};

export default Icon3RnDLogo;
