import React, {useContext} from "react";
import styled from "styled-components/macro";
import { Avatar } from "@mui/material";
import {UserT} from "@contexts/UserContext";
import {stringAvatar} from "@utils/user";
import {StyleContext} from "@theme/AppStyles";
import {PersonOff} from "@mui/icons-material";

interface propsT {
  user: UserT;
}

const UserAvatar = ({user}:propsT) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.accentSecondary;
  if (user?.isAppAuthorized) {
    return(<StyledAvatar color={color}>{stringAvatar(user)}</StyledAvatar>)
  }
  return <StyledAvatar color={color} ><PersonOff/></StyledAvatar>
}

export default UserAvatar

const StyledAvatar = styled(Avatar)<{ color: string }>`
  &.MuiAvatar-root {
    background-color: ${(p) => p.color};
    width: 28px;
    height: 28px;
    font-size: 16px;
    font-weight: bold;
  }
`;


