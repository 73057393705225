import React from 'react';
import styled from 'styled-components/macro';
import { faqs } from '@components/pages/infoFaq/info.faq';
import { FlexRow } from '@components/layout/FlexStyles';
import FaqItem from '@components/pages/infoFaq/FaqItem';

const Faq = () => {

  const returnCategoryFaqs = (cat: string, cat_index: number) => {
    return(
      <>
        <FlexRow><CategoryTitle>{cat}</CategoryTitle></FlexRow>
        { faqs[cat].map((faq, index) => (
          <FaqItem
            id={`${cat_index}_${index}`}
            question={faq.question}
            response={faq.response}
            />
        ))}
      </>
    )
  }

  return(
    <FaqContainer>
      <FlexRow><FaqTitle>FAQ - Frequently Asked Questions</FaqTitle></FlexRow>
      <>
        { Object.keys(faqs).map((cat, cat_index) => (
          returnCategoryFaqs(cat, cat_index)))}
      </>
    </FaqContainer>
  );
}

export default Faq;

const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const FaqTitle = styled.div`
  display: flex;
  width: 100%;
  font-size: 20px;
  padding-top: 5px;
`;

const CategoryTitle = styled.div`
  display: flex;
  font-size: 16px;
  margin-top: 24px;
`;