import React, { useContext } from 'react';
import PageContainer from '@components/pages/PageContainer';
import PagePanel from "@components/pages/PagePanel";
import PageTiles from "@components/layout/PageTiles";

import {UserContext} from "@contexts/UserContext";
import {asedaCards, ChosmosCards} from "@app_config/homeCards";
import { MenuContext } from '@contexts/MenuContext';
// import NewUser from '@components/user/NewUser';

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgHome = () => {
  const { user } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  debug && console.log("PgHome | userInfo:", user);

  if (activeMenuId !== 'home') setActiveMenuId('home');

  return (
    <PageContainer title='Welcome'>
      {/* TODO - when subscriptions available -- check if new user
      <NewUser /> : null
      */}
      <PagePanel title={"FAERS Capabilities"}>
        <PageTiles
            isAuthorized={user.isAppAuthorized}
            cards={ChosmosCards}
        />
      </PagePanel>
      <PagePanel title={"Additional AsedaSciences Information and Application/Services"}>
        <PageTiles
            isAuthorized={user.isAppAuthorized}
            cards={asedaCards}
        />
      </PagePanel>
    </PageContainer>
  );
};

export default PgHome;

