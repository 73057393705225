import React, { useContext, ReactNode, useEffect, useState, useRef } from 'react';
import { UserContext } from '@contexts/UserContext';
import * as AWSCognitoIdentity from 'amazon-cognito-identity-js';
import useCognito from '@as_core/account/useCognito';
import { useNavigate } from 'react-router-dom';
import LogoutAlertDialog from '@as_core/account/forms/LogoutAlertDialog';

/*
  Primary component to handle the user authentication and inactivity states
  Parameters:
    CHECK_AUTHENTICATION_PERIOD: time interval for the app to recheck inactivity and time since authenticated
    INACTIVITY_TIME_OUT: period of user inactivity to be considered inactive
 */
import config from '@app_config/cognito.json';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
const CHECK_AUTHENTICATION_PERIOD:number = 60 * 1000; // in milliseconds = 1 minute
// TODO -- need to check to make sure inactivity timeout is working correctly -- for the moment set very long
const INACTIVITY_TIME_OUT:number = 3000 * 30 * 1000; // in milliseconds = 30 minutes

interface AuthenticatorProps {
  children: ReactNode;
}

const debug: boolean = false;
const AuthWrapper: React.FC<AuthenticatorProps> = ({ children }) => {
  const { logout, isTokenExpired, getAsedaRole, getSecondsSinceAuthentication } = useCognito();
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { user, setUser } = useContext(UserContext);
  const { isAuthenticated } = user;
  debug && console.log('AuthWrapper | .isLoading', user.isLoading, '.isAuthenticated', user.isAuthenticated,
    '.isRegistered', user.isRegistered, 'alertOpen', alertOpen);

  let timer: string | number | NodeJS.Timeout;

  // need to use references for functions so do not have stale states
  const isAuthenticatedRef = useRef(user?.isAuthenticated);
  const userSessionRef = useRef(user?.authSession);
  isAuthenticatedRef.current = user.isAuthenticated;
  userSessionRef.current = user.authSession;

  // action to take when user inactive or too long since authentication - dhr
  const logoutActionEmail = (authEmail: string, showAlert:boolean) => {
    console.log('logout Action by email triggered | authEmail', authEmail, new Date().toLocaleString());
    if (showAlert) setAlertOpen(true);
    if (authEmail) logout(authEmail);
    setUser((prev) => ({
      ...prev, isLoading: false, isAuthenticated: false,
      authId: null, authEmail: '', authSession: null,
      isRegistered: false, regInfo: null
    }));
    navigate('/user/login');
  };

  const logoutActionSession = (session: AWSCognitoIdentity.CognitoUserSession, showAlert:boolean) => {
    console.log('logout Action by session triggered | session', session, new Date().toLocaleString());
    const authEmail = session?.getIdToken().payload?.email;
    logoutActionEmail(authEmail, showAlert);
  };

  // listeners for the inactivity timeout
  const addActivityListeners = (authEmail:string) => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer(authEmail);
      });
    });
  }

  const removeActivityListeners = () => {
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, resetTimer);
    });
  }

  const checkAuthenticationStatus = () => {
    debug && console.log('checkActiveAuthentication', isAuthenticatedRef.current,
      isTokenExpired(userSessionRef.current), getSecondsSinceAuthentication(userSessionRef.current), new Date().toLocaleString());
    if (isAuthenticatedRef.current) {
      if (isTokenExpired(userSessionRef.current)) {
        debug && console.log('tokenExpired --- forcing logout', new Date().toLocaleString());
        removeActivityListeners();
        logoutActionSession(userSessionRef.current, true);
      }
    }
  };

  // Set the event timer for checking user token not expired;
  useEffect(() => {
    const timerId = setInterval(
      checkAuthenticationStatus, CHECK_AUTHENTICATION_PERIOD
    );
    return () => clearInterval(timerId);
  }, []);

  // AutoLogout : this function sets the timer that logs out the user after defined amount of inactivity
  const handleLogoutTimer = (authEmail:string) => {
    timer = setTimeout(() => {
      logoutActionEmail(authEmail, true);
    }, INACTIVITY_TIME_OUT);
  };

// User activity event -- resets the timer if it exists.
  const resetTimer = () => { if (timer) clearTimeout(timer); };

  // For inactivity -- when authentication state changes adds event listeners to the window
  // Each time any event is triggered, reset timer
  // If not events over specified timeout (INACTIVITY_TIME_OUT) the app automatically logs out.
  useEffect(() => {
    if (isAuthenticated) {
      console.log('Inactivity Time Out Check -- activating ', user?.authEmail, Date().toLocaleString());
      addActivityListeners(user?.authEmail);
    } else {
      console.log('Inactivity Time Out-- de-activating', new Date().toLocaleString());
      removeActivityListeners();
    }
  }, [isAuthenticated]);


  // If user not authenticated (first time) -- check to see if user has active session using cognito
  // -- needed for the redirects back from Stripe (or other applications)
  useEffect(() => {
    if (!isAuthenticatedRef.current) {
      const poolData = { UserPoolId: config.userPoolId, ClientId: config.clientId };
      let cognitoUser = null;
      const userPool = new AWSCognitoIdentity.CognitoUserPool(poolData);
      try {
        cognitoUser = userPool.getCurrentUser();
      } catch {
        console.error('getCurrentUser error');
      }
      debug && console.log('cognitoUser:', cognitoUser);

      if (cognitoUser !== null) {
        // only reload if not longer than authentication timeout
        cognitoUser.getSession(function (err: { message: any; }, session: AWSCognitoIdentity.CognitoUserSession) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }
          debug && console.log('Reloading user session to confirm authentication', session);
          debug && console.log('isTokenExpired', isTokenExpired(session));
          if (isTokenExpired(session)) {
            console.log('Expiring user authentication state --- tokenExpired ...', session, new Date().toLocaleString());
            logoutActionSession(session, true);
          } else {
            const userId = session.getIdToken().payload;
            const dataKey = 'storedUserRegData_' + userId?.sub;
            debug && console.log(`AuthWrapper | locating user data using key "${dataKey}"`);
            let userReg = {};
            if (localStorage.getItem(dataKey) !== null) {
              const storedUserData = localStorage.getItem(dataKey);
              userReg = JSON.parse(storedUserData);
              debug && console.log(`AuthWrapper | user data read from local storage key "${dataKey}"`, userReg);
            } else {
              console.error('User authenticated through session, but user registration data is not stored');
              navigate('/user/register');
            }
            setUser((prev) => ({
              ...prev,
              isLoading: false, isAuthenticated: true,
              authId: userId['cognito:username'], authEmail: userId.email, authSession: session,
              userRoles: getAsedaRole(session),
              isRegistered: (Object.keys(userReg).length > 0), regInfo: userReg
            }));
          }
        });
      } else {
        setUser((prev) => ({
          ...prev,
          isLoading: false
        }));
      }
    }
  }, [user.isLoading]);

  return <>
    {children}
    <LogoutAlertDialog
      alertOpen={alertOpen}
      closeAlert={()=>setAlertOpen(false)}
    />
  </>;
};

export default AuthWrapper;
