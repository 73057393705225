import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const IconMarket = ({size = 24}) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M7.66699 17.1665C7.00033 17.1665 6.41699 17.7498 6.41699 18.4165C6.41699 19.0832
          7.00033 19.6665 7.66699 19.6665C8.33366 19.6665 8.91699 19.0832 8.91699 18.4165C8.91699 17.7498
          8.33366 17.1665 7.66699 17.1665Z" fill={color}/>
          <path d="M14.25 17.1665C13.5833 17.1665 13 17.7498 13 18.4165C13 19.0832 13.5833 19.6665
          14.25 19.6665C14.9167 19.6665 15.5 19.0832 15.5 18.4165C15.5833 17.7498 15 17.1665 14.25
          17.1665Z" fill={color}/>
          <path d="M1.75 5.4165H4.41667L6.16667 15.4998H16.75L17.5833 13.2498"
                stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M8 10.2318L12.5 13L17 10.2318V4.76821L12.5 2L8 4.76821V10.2318Z"
                stroke={colorHighlight} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>
    </>
  )
}

export default IconMarket;