import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@components/pages/PageContainer';
import PagePanel from "@components/pages/PagePanel";
import {UserContext} from '@contexts/UserContext';
import { MenuContext } from '@contexts/MenuContext';
import { FlexRow, FlexItem} from '@components/layout/FlexStyles';
import SelectorDropdown from '@components/controls/SelectorDropdown';
import { BasicTextInput } from '@as_core/account/forms/FormStyles';
import TextButton from '@components/controls/TextButton';
import useFaersData from '@utils/useFaersData';
import useCognito from '@as_core/account/useCognito';
import BasicTable from '@components/composite/BasicTable';

const Options = [
  { value: 'demographic', label: 'Demographic', showFields: [
      { value: 'primaryid', label: 'primaryid', width: 150 },
      { value: 'caseid', label: 'caseid', width: 150 },
      { value: 'i_f_code', label: 'i_f_code', width: 150 },
      { value: 'age', label: 'age', width: 50 },
      { value: 'age_cod', label: 'age_cod', width: 50 },
      { value: 'age_grp', label: 'age_grp', width: 150 },
      { value: 'sex', label: 'sex', width: 50 },
      { value: 'wt', label: 'wt', width: 50 },
      { value: 'wt_cod', label: 'wt_cod', width: 50 },
    ]
  },
  {
    value: 'drug', label: 'Drug', showFields: [
      { value: 'primaryid', label: 'primaryid', width: 150 },
      { value: 'a_compound_id', label: 'a_compound_id', width: 150 },
      { value: 'caseid', label: 'caseid', width: 150 },
      { value: 'drugname', label: 'drugname', width: 150 },
      { value: 'substance', label: 'substance', width: 150 }
    ]
  }
]
export interface Dictionary {
  [key: string]: any;
}

type ResultsT = {
  fields: Array<any>,
  rows: Array<any>
}

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgSearch = () => {
  const [resultsLoaded, setResultsLoaded] = useState<boolean>(false);
  const [results, setResults] = useState<ResultsT>({ fields: [], rows: [] });
  const [selected, setSelected] = useState('');
  const [limit, setLimit] = useState<number>(10);
  const { getTableData } = useFaersData()
  const { getToken } = useCognito();

  const { user } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  debug && console.log("PgSearch | userInfo:", user);

  if (activeMenuId !== 'search') setActiveMenuId('search');

  const handleLimitChange = (e) => {
    console.log('handleLimitChange', e.target.value);
    setLimit(e.target.value)
  }

  const handleSubmit = () => {
    console.log('handleSubmit: ', selected, limit);
    getTableData(getToken(), selected, limit).then(
      (response) => {
        console.log(response?.data);
        setResultsLoaded(true);
        const info = response?.data['info'];
        const selectedOption = Options.find((f) => f.value === selected);
        const newResults = {
          fields: selectedOption.showFields,
          rows: info?.rows
        };
        setResults(newResults);
      }
    );
  }
  console.log('results:', results);

  return (
    <PageContainer title='Search Available Databases'>
      <PagePanel title={"Search Databases"}>
        <FlexRow v_centered>
          <FlexItem width={'200px'}>
            <SelectorDropdown
              label={'Faers Data Table'}
              value={selected}
              options={Options}
              width={200}
              setValue={(v) => setSelected(v)}
            />
          </FlexItem>
          <FlexItem width={'10px'} />
          <FlexItem>Limit:</FlexItem>
          <FlexItem>
            <BasicTextInput
              value={limit}
              onChange={handleLimitChange}
              />
          </FlexItem>
          <FlexItem>
            <TextButton
              label={'Query FAERS Database'}
              width={200}
              height={30}
              onClick={handleSubmit}
              />
          </FlexItem>
        </FlexRow>
      </PagePanel>
      <SearchResults>
        { resultsLoaded ?
          <FlexRow width={'100%'} h_centered>
            <BasicTable fields={results?.fields} rows={results?.rows} />
          </FlexRow>
          :
          <FlexRow h_centered width={'100%'}>
            <NoResults>Perform search for results to be available</NoResults>
          </FlexRow>
        }
      </SearchResults>
    </PageContainer>
  );
};

export default PgSearch;

const SearchResults = styled.div`
  display: flex;
  width: calc(100vw - 60px);
  height: calc(100vh - 200px);
  justify-content: center;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
  ::-webkit-scrollbar-corner {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
`;

const NoResults = styled.div`
  display: flex;
  font-size: 14px;
  font-style: italic;
  color: ${(p) => p.theme.palette.textSecondary};
`;