import React from 'react';
import styled from 'styled-components/macro';
import StationaryModal from './StationaryModal';
import TextButton from '@components/controls/TextButton/TextButton';

type Props = {
  title: string;
  message: string;
  alertOpen: boolean;
  closeAlert: () => void;
  width?: number;
  height?: number;
  opacity?: number;
};

const debug = false;
const AlertDialog = (props: Props) => {
  const {
    title,
    message,
    alertOpen,
    closeAlert,
    width = 300,
    height = 200,
    opacity = 1.0,
  } = props;
  debug &&
    console.log(
      'AlertDialog | title:',
      title,
      ' message:',
      message,
      ' alertOpen',
      alertOpen
    );

  return (
    <StationaryModal
      title={title}
      modalOpen={alertOpen}
      onCloseClick={closeAlert}
      opacity={opacity}
      width={width}
      height={height}
    >
      <Content>
        <Message height={height} width={width}>
          {message}
        </Message>
        <CancelContainer>
          <TextButton label={'Close Alert'} onClick={closeAlert} />
        </CancelContainer>
      </Content>
    </StationaryModal>
  );
};

export default AlertDialog;

const Content = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
`;

const Message = styled.div<{ height: number; width: number }>`
  display: flex;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-bottom: 15px;
  width: calc(${(p) => p.width} - 100) px;
  height: calc(${(p) => p.height} - 150) px;
  border-radius: 10px;
  background-color: white;
  color: darkred;
`;

const CancelContainer = styled.div``;
