import React from 'react';

import IconSearch from '@components/icons/IconSearch';
import MarketIcon from '@components/icons/IconMarket';
import Icon3RnDLogo from '@components/icons/Icon3RnDLogo';
import NaturalProductsIcon from '@components/icons/naturalProducts.icon';

export const asedaCards = [
  {
    title: 'Aseda Sciences Website',
    authorizedOnly: false,
    newTab: true,
    icon: <img src={'/img/AsedaSciences_logo.svg'} alt={'AsedaSciences'} width={64} />,
    link: 'https://asedasciences.com',
    description: 'Look at the main AsedaSciences website for more information',
  },
  {
    title: '3RnD Application',
    authorizedOnly: false,
    newTab: true,
    icon: <Icon3RnDLogo size={64} />,
    link: 'https://app-prd.thewarriors3.com',
    description: 'Coming Soon',
  },
  {
    title: 'Compound Marketplace',
    authorizedOnly: false,
    newTab: true,
    icon: <MarketIcon size={64} />,
    link: '',
    description: 'Coming Soon',
  },
];

export const ChosmosCards = [
  {
    title: "Faers Data Explorer",
    authorizedOnly: true,
    newTab: false,
    icon: <img src='/img/FAERS_Logo.svg' width={84} alt={'FAERS'} />,
    link: "/faers/dashboard",
    subscriptions: ["faers", "chosmos"],
    description: "Cleaned and current version of the FDA FAERS adverse event data."
  },
  {
    title: "Natural Products (Coconut)",
    authorizedOnly: true,
    newTab: false,
    icon: <NaturalProductsIcon size={64} />,
    link: "/naturalproducts/dashboard",
    subscriptions: ["naturalproducts", "chosmos"],
    description: "Search across multiple natural products databases to retrieve relevant compound data, activity, and known toxicity."
  },
  {
    title: "All Databases Search",
    authorizedOnly: true,
    newTab: false,
    icon: <IconSearch size={64} />,
    link: "/submissions",
    description: "Search through the all the available databases (ChEMBL, FAERS, Coconut, ...) for a complete view of available data for a compound.",
  }
];


