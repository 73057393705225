import React from 'react';
import { UserT } from '../contexts/UserContext';

// in case need console log messages
const debug = false;

// utility function to take username and convert to initials
export function stringAvatar(user:UserT) {
  debug && console.log('stringAvatar | user', user);
  let initials = '?';
  if (user?.regInfo?.name) {
    initials = user.regInfo.name?.first[0] + user.regInfo.name?.last[0];
  }
  return <>{initials}</>;
}
