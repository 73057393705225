import styled from 'styled-components/macro';
import {APP_CONFIG} from "@app_config/app";

const PageTitle = ({title}) => {
  return(
    <Title>
      <AppTitle>{APP_CONFIG.name}: </AppTitle>
      <SubTitle>{title}</SubTitle>
    </Title>
  )
}
export default PageTitle;

const Title = styled.div`
  display: flex;
  padding-left: 15px;
  vertical-align: center;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: ${(p) => p.theme.sizes.default};
`;

const AppTitle = styled(Title)`
  padding-left: 0;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const SubTitle = styled(Title)`
  padding-left: 4px;
`;