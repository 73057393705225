import { useContext } from "react";
import { StyleContext } from "@theme/AppStyles";

interface IconScoreProps {
  size?: number;
  score?: number;
}

const arrColors = [
  "#e878ab",
  "#e878ab",
  "#e878ab",
  "#e878ab",
  "#e878ab",
  "#fabde0",
  "#f9badf",
  "#89CCCE",
  "#7BD8D3",
  "#6EE4D8",
  "#55B9E4",
];

const IconScore = (props: IconScoreProps) => {
  const { size = 24, score = 0 } = props;
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;

  const localScore = score < 0 ? 0 : score > 10 ? 10 : score;

  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 52 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <radialGradient
          id="radialGradient"
          cx="-258.4935"
          cy="425.2399"
          r="1"
          gradientTransform="matrix(1.119750e-15 18.2869 33.8595 -2.073297e-15 -14405.1777 4733.3975)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#83F6C9" />
          <stop offset="1" stopColor="#4CADE9" />
        </radialGradient>
        <linearGradient
          id="linearGradient"
          gradientUnits="userSpaceOnUse"
          x1="22"
          y1="23"
          x2="22"
          y2="2.1148"
          gradientTransform="matrix(1 0 0 -1 0 42)"
        >
          <stop offset="0" stopColor="#83F6C9" />
          <stop offset="1" stopColor="#4CADE9" />
        </linearGradient>
        <path
          fill="url(#radialGradient)"
          d="M15.3,7.3c0.9,0.2,1.8,0.5,2.7,0.9c0.1,0.1,0.2,0.1,0.3,0.2l0,5.6l-1.9,3.4c-0.9-1.2-2.2-1.9-3.8-1.9
	c-2.6,0-4.7,2.1-4.7,4.6c0,2.4,1.9,4.4,4.3,4.6l-4,7.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.4-0.2-0.6-0.5-0.7-1
	l-0.2-1.8c-0.2-0.1-0.4-0.2-0.5-0.4l-1.7,0.4c-0.4,0.1-0.9,0-1.1-0.4c-0.6-0.7-1.2-1.5-1.7-2.3c-0.2-0.4-0.2-0.8,0-1.2l0.9-1.5
	c-0.1-0.2-0.1-0.4-0.2-0.6l-1.6-0.7C0.3,22.2,0,21.8,0,21.3v-2.6c0-0.5,0.3-0.9,0.7-1.1l1.6-0.7c0.1-0.2,0.1-0.4,0.2-0.6l-0.9-1.5
	c-0.2-0.4-0.2-0.8,0-1.1c0.5-0.8,1-1.6,1.7-2.3C3.5,11,4,10.8,4.4,10.9l1.7,0.4c0.2-0.1,0.3-0.3,0.5-0.4l0.2-1.8
	c0-0.4,0.3-0.8,0.7-1c0.9-0.4,1.8-0.7,2.7-0.9c0.4-0.1,0.8,0.1,1.1,0.4L12.4,9H13l1.2-1.4C14.4,7.3,14.9,7.2,15.3,7.3z"
        />
        <path
          fill={color}
          d="M27.2,0c1.4,0,2.6,1,2.6,2.3c0,1-0.7,1.9-1.7,2.2v8.9c0,0.4,0.1,0.7,0.3,1.1l10.8,17.3c1.1,1.7,1.1,3.8,0,5.4
	C38.1,39,36.1,40,33.9,40H10.1c-2.2,0-4.1-1-5.2-2.7c-1.1-1.7-1.1-3.7-0.1-5.4l10.8-17.3c0.2-0.3,0.3-0.7,0.3-1.1V4.6
	c-1-0.3-1.7-1.2-1.7-2.2c0-1.3,1.2-2.3,2.6-2.3H27.2z M23,3.1h-2c-1.1,0-2,0.9-2,2v10.4c0,0.4-0.1,0.8-0.3,1.1L9.1,30.9
	c-0.2,0.3-0.3,0.7-0.3,1.1c0,1.1,0.9,2,2,2h22.5c0.4,0,0.8-0.1,1.1-0.3c0.9-0.6,1.2-1.9,0.6-2.8l-9.6-14.3c-0.2-0.3-0.3-0.7-0.3-1.1
	V5.1C25,4,24.1,3.1,23,3.1z"
        />
        <path
          fill="url(#linearGradient)"
          d="M4.8,31.7c-1.1,1.7-1.1,3.7,0.1,5.4
	c1.1,1.7,3.1,2.7,5.2,2.7h23.8c2.2,0,4.1-1,5.2-2.7c1.1-1.7,1.1-3.7,0-5.4c-1.6-2.6-6.3-10.2-7.3-11.7c-0.3-0.4-4-1.9-7.3-0.5
	c-2.8,1.2-2.4,4.2-7.4,5.3c-4.9,1.1-7.4-1.1-7.5-1.2C8.7,25.5,7,28.2,4.8,31.7z"
        />
        <path
          fill={color}
          d="M26.3,31c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3C29.3,32.4,28,31,26.3,31z"
        />
        <path
          fill={color}
          d="M32.6,29.5c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C34.6,30.4,33.7,29.5,32.6,29.5z"
        />
        <circle fill={arrColors[localScore]} cx="41" cy="10" r="10" />
        <text x="41" y="14" textAnchor="middle" fontSize="12" fill="black">
          {localScore}
        </text>
      </svg>
    </>
  );
};

export default IconScore;
