import React, {useState, useEffect} from "react";
import styled from "styled-components/macro";
import TopMenu from "../menus/TopMenu";
import { FlexItem } from '@components/layout/FlexStyles';
import UserAccountPolicies from '../../as_core/account/fields/UserAccountPolicies';
import { useApiInfo } from '@utils/useApiInfo';
import useCognito from '../../as_core/account/useCognito';

interface propsT {
  title: string;
  children: any;
}

// TODO: Update the calls to retrieve the Info
const debug = true;
const PageContainer = (props: propsT) => {
  const { title } = props;
  const [infoLoaded, setInfoLoaded] = useState<boolean>(false);
  const [version, setVersion] = useState('');
  const { getToken } = useCognito();
  const { getApiInfo } = useApiInfo();

  useEffect(() => {
    if (!infoLoaded) {
      getApiInfo(getToken()).then((response) => {
        setInfoLoaded(true);
        debug && console.log("apiInfo {response}", response);
        const apiInfo = response?.info;
        const ui = process.env.REACT_APP_TYPE;
        const version = ui.concat(
          '|',
          apiInfo?.api?.version,
          ':',
          apiInfo?.api?.build_id,
          '|',
          apiInfo?.db?.type,
          ':',
          apiInfo?.db.version
        );
        setVersion(version);
      });
    }
  }, []);

  return (
    <Container>
      <TopMenu title={title} />
      <PageContent>
        {props.children}
      </PageContent>
      <PageFooter>
        <FlexItem></FlexItem>
        <UserAccountPolicies account={true}/>
        <Version>vers:{version}</Version></PageFooter>
    </Container>
  );
};

export default PageContainer;

const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: calc(100vw - 50px);
  top: 0;
  padding: 0;
  margin: 0;
  left: 50px;
`;

const PageContent = styled.div`
  position: relative;
  width: calc(100vw - 60px);
  padding: 0;
  margin: 5px;
  flex: auto;
  height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const PageFooter = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 30px;
  bottom: 0;
  color: ${(p) => p.theme.palette.textQuaternary};
`;

const Version = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  width: 150px;
  font-size: 8px;
`;
