import React from "react";
import styled from "styled-components";
import PageContainer from "../components/pages/PageContainer";

// Show the main page of tiles for the site (linked to the logo)
const PgSettings = () => {

  return (
    <PageContainer title="Settings"  >
      <SettingsDetails></SettingsDetails>
    </PageContainer>
  );
};

const SettingsDetails = styled.div`
  height: calc(100vh - 72px);
  width: calc(100vw - 51px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  & ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  & ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  & ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

export default PgSettings;
