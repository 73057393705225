import React, { useContext } from 'react';
import PageContainer from '@components/pages/PageContainer';
import PagePanel from "@components/pages/PagePanel";
import { MenuContext } from '@contexts/MenuContext';
import { FlexRow } from '@components/layout/FlexStyles';
import Sankey from '@components/graphs/Sankey';

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgExplore = () => {
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  debug && console.log("PgExplore");

  if (activeMenuId !== 'explore') setActiveMenuId('explore');

  return (
    <PageContainer title='Explore FAERS Data'>
      <PagePanel title={"Explore Databases"}>
        <FlexRow v_centered h_centered height={'500px'}>
          <Sankey />
        </FlexRow>
      </PagePanel>
    </PageContainer>
  );
};

export default PgExplore;
