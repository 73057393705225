import React from 'react';
import styled from 'styled-components/macro';

import SingleSelect from "./SingleSelect";

interface SelectorDropdownProps {
  label: string;
  value: string;
  optionKey?: string;
  width?: number;
  options: any;
  setValue: (v) => void;
}

const SelectorDropdown = (props:SelectorDropdownProps) => {

  function handleChange(v) {
    console.log('handleChange', v.target.value);
    props.setValue(v.target.value);
  }

  return (
    <SelectorContainer>
      <SingleSelect
        {... props}
        id={props.label}
        onChange={handleChange}
      />
    </SelectorContainer>
  );
};

export default SelectorDropdown;

const SelectorContainer = styled.div`
  display: flex;
`;

