import React, { useContext } from 'react';
import { Button, Menu } from '@mui/material';
import MenuTooltip from '@components/controls/MenuButton/MenuTooltip';
import { useNavigate } from 'react-router-dom';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import UserAvatar from '../../as_core/account/components/UserAvatar';
import PulldownMenuItem from './PulldownMenuItem';
import { UserT, UserContext } from '../../contexts/UserContext';
import useCognito from '../../as_core/account/useCognito';
import LogoutIcon from '@mui/icons-material/Login';
import LoginIcon from '@mui/icons-material/Login';

const debug = false;

const UserProfileMenu = () => {
  const { user } = useContext(UserContext);
  debug && console.log('UserProfileMenu | user: ', user);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { logout } = useCognito();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = () => {
    setAnchorEl(null);
    navigate('/user');
  };

  const handleLogout = () => {
    logout(user?.authEmail);
  };

  const getMenus = (user:UserT) => {
    let pullDownMenus = [];
    if (!user.isAppAuthorized) {
      if (user.isAuthenticated) {
        pullDownMenus.push(
          <PulldownMenuItem
            key={'PD_logout'}
            icon={<LogoutIcon />}
            label={'Sign-Out'}
            onClick={handleLogout}
          />
        );
      } else {
        pullDownMenus.push(
          <PulldownMenuItem
            key={'PD_login'}
            icon={<LoginIcon />}
            label={'Sign-In'}
            onClick={() => navigate('/user/login')}
          />
        );
      }
      return pullDownMenus;
    }
    // user Account Menu Item
    pullDownMenus.push(
      <PulldownMenuItem
        key='PD_account'
        icon={<ManageAccountsOutlinedIcon />}
        label='Account'
        onClick={handleAccount}
      />
    );
    pullDownMenus.push(
      <PulldownMenuItem
        key='PD_logout'
        icon={<LogoutIcon />}
        label={'Sign-Out'}
        onClick={handleLogout}
      />
    );
    return pullDownMenus;
  };

  return (
    <>
      <MenuTooltip text={'Account'} tooltipPlacement={'bottom-end'}>
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <UserAvatar user={user} />
        </Button>
      </MenuTooltip>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        {getMenus(user)}
      </Menu>
    </>
  );
};

export default UserProfileMenu;
