export const APP_CONFIG = {
    name: 'ChOSMOS',
    tagline: 'The Universe of Chemical Data',
    logo: '/img/ChOMOS_Logo.svg',
    applications: ['FAERS_CONFIG', 'NATURAL_PRODUCTS_CONFIG']
}

export const FAERS_CONFIG = {
    name: 'FAERS Data Explorer',
    menuItems: {}
}

export const NATURAL_PRODUCTS_CONFIG = {
    name: 'Natural Products',
    menuItems: {}
}