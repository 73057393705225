
export const roundOf = (n, p) => {
  // https://stackoverflow.com/a/49862458
  const n1 = n * Math.pow(10, p + 1);
  const n2 = Math.floor(n1 / 10);
  if (n1 >= n2 * 10 + 5) {
    return (n2 + 1) / Math.pow(10, p);
  }
  return n2 / Math.pow(10, p);
};

export const handleError = (err) => {
  if (err?.response?.status === 400) {
    console.log("ERROR", err?.response?.data?.errors);
    err?.response?.data?.errors?.forEach((element) => {
      alert(element);
    });
  } else {
    console.log("ERROR CODE", err?.response?.status);
    console.log("ERROR", err?.response?.data);
  }
};

