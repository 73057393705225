import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import PgHome from '@pages/PgHome';
import PgDashboard from '@pages/faers/PgDashboard';
import PgSearch from "@pages/faers/PgSearch";
import PgInfo from '@pages/PgInfo';
import PgDevTest from '@pages/PgDevTest';
import PgSettings from '@pages/PgSettings';
import PgUserAccount from '@pages/PgUserAccount';

import PgExplore from '@pages/PgExplore';
import PgAuthentication from '@pages/public/PgAuthentication';

const RoutesProtected = () => {
  return (
    <Content>
      <Routes>
        <Route path='/' element={<Navigate replace to='/home' />} />
        <Route path='/home' element={<PgHome />} />
        <Route path='/info' element={<PgInfo />} />
        <Route path='/user' element={<PgUserAccount />} />
        <Route path='/user/:action' element={<PgAuthentication />} />
        <Route path='/settings' element={<PgSettings />} />
        <Route path='/faers/dashboard' element={<PgDashboard />} />
        <Route path='/faers/dashboard/:id' element={<PgDashboard />} />
        <Route path='/faers/explore' element={<PgExplore />} />
        <Route path='/faers/search' element={<PgSearch />} />
        <Route path='/devtest' element={<PgDevTest />} />
        <Route path='/*' element={<Navigate replace to='/' />} />
        <Route path='*' element={<Navigate replace to='/' />} />{' '}
        {/* handle 404 issue on Amazon S3-Hosted */}
      </Routes>
    </Content>
  );
};

export default RoutesProtected;

const Content = styled.div`
  position: absolute;
  margin: 0;
  top: 0;
  left: 50px;
  width: calc(100vw - 50px);
  height: 100vh;
  background: ${(p) => p.theme.palette.backgroundPrimary};
`;
