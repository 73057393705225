import HomeIcon from '@components/icons/IconHome';
import IconInfo from '@components/icons/info.icon';
import IconSearch from "@components/icons/IconSearch";
import IconDashboard from '@components/icons/IconDashboard';
import IconDataExplorer from '@components/icons/dataExplorer.icon';

export const menuItems = [
  {
    id: 'home',
    name: 'Home',
    label: 'HM',
    pathname: '/home',
    icon: <HomeIcon />,
    authorizedOnly: false,
  },
  {
    id: 'dashboard',
    name: 'FAERS Data Overview',
    label: 'SDB',
    pathname: '/faers/dashboard',
    icon: <IconDashboard />,
    authorizedOnly: true,
  },
  {
    id: 'explore',
    name: 'Data Explorer',
    label: 'SDB',
    pathname: '/faers/explore',
    icon: <IconDataExplorer />,
    authorizedOnly: true,
  },
  {
    id: 'search',
    name: 'Search Databases',
    label: 'SDB',
    pathname: '/faers/search',
    icon: <IconSearch />,
    authorizedOnly: true,
  },
  {
    id: 'info',
    name: 'Info/FAQ',
    label: 'FAQ',
    pathname: '/info',
    icon: <IconInfo />,
    authorizedOnly: true,
  },
];
