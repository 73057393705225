import React from "react";
import styled from "styled-components/macro";
import {MenuItem} from "@mui/material";

interface PropsT {
  icon: any;
  label: string;
  onClick: () => void;
}

const PulldownMenuItem = (props: PropsT) => {
  const { icon, label, onClick } = props;
  return(
      <StyledMenuItem onClick={onClick}>
        <MenuIcon>{icon}</MenuIcon>
        <MenuText>{label}</MenuText>
      </StyledMenuItem>
  )
}

export default PulldownMenuItem;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 14px;
    align-items: center;
    justify-items: flex-start;
    justify-content: flex-start;
    :hover {
      color: ${(p) => p.theme.palette.accentSecondary};
      background-color: ${(p) => p.theme.palette.backgroundQuaternary};
    }
  }
`;

const MenuIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30px;
`;

const MenuText = styled.div`
  display: flex;
  width: 60px;
`;