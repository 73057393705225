import { ResponsiveSankey } from '@nivo/sankey'

const data = {
  "nodes": [
    {
      "id": "John",
      "nodeColor": "hsl(150, 70%, 50%)"
    },
    {
      "id": "Raoul",
      "nodeColor": "hsl(132, 70%, 50%)"
    },
    {
      "id": "Jane",
      "nodeColor": "hsl(239, 70%, 50%)"
    },
    {
      "id": "Marcel",
      "nodeColor": "hsl(47, 70%, 50%)"
    },
    {
      "id": "Ibrahim",
      "nodeColor": "hsl(253, 70%, 50%)"
    },
    {
      "id": "Junko",
      "nodeColor": "hsl(343, 70%, 50%)"
    }
  ],
  "links": [
    {
      "source": "Junko",
      "target": "John",
      "value": 70
    },
    {
      "source": "Junko",
      "target": "Raoul",
      "value": 172
    },
    {
      "source": "Junko",
      "target": "Jane",
      "value": 102
    },
    {
      "source": "Junko",
      "target": "Ibrahim",
      "value": 136
    },
    {
      "source": "Junko",
      "target": "Marcel",
      "value": 137
    },
    {
      "source": "Raoul",
      "target": "Marcel",
      "value": 27
    },
    {
      "source": "Raoul",
      "target": "Ibrahim",
      "value": 64
    },
    {
      "source": "Marcel",
      "target": "Jane",
      "value": 110
    },
    {
      "source": "Marcel",
      "target": "John",
      "value": 197
    },
    {
      "source": "Marcel",
      "target": "Ibrahim",
      "value": 11
    },
    {
      "source": "Ibrahim",
      "target": "Jane",
      "value": 180
    },
    {
      "source": "John",
      "target": "Jane",
      "value": 193
    },
    {
      "source": "John",
      "target": "Ibrahim",
      "value": 62
    }
  ]
}

const Sankey = () => {
  return(
    <ResponsiveSankey
      data={data}
      margin={{ top: 40, right: 160, bottom: 40, left: 50 }}
      align="justify"
      colors={{ scheme: 'pastel1' }}
      nodeOpacity={1}
      nodeHoverOthersOpacity={0.8}
      nodeThickness={24}
      nodeSpacing={24}
      nodeBorderWidth={0}
      nodeBorderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            1
          ]
        ]
      }}
      nodeBorderRadius={3}
      linkOpacity={0.5}
      linkHoverOthersOpacity={0.2}
      linkContract={3}
      linkBlendMode="lighten"
      enableLinkGradient={true}
      labelPosition="outside"
      labelOrientation="vertical"
      labelPadding={16}
      labelTextColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            1
          ]
        ]
      }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          translateX: 130,
          itemWidth: 100,
          itemHeight: 14,
          itemDirection: 'right-to-left',
          itemsSpacing: 2,
          itemTextColor: '#999',
          symbolSize: 14,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#FFF'
              }
            }
          ]
        }
      ]}
    />
  )
}

export default Sankey;