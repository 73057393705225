import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import InputText from "./InputText";
import RadioButton from "../components/RadioButton";

interface propsT {
  id: string;
  label?: string;
  name_value: string;
  type_value: string;
  onChange: any;
  placeHolder?: string;
  activeUpdate?: boolean;
  width?: number;
  error?: boolean;
  handleBlur?: ()=>void;
  children?: any;
}

const orgTypes = [
  { value: 'academic', label: 'Academic' },
  { value: 'corporate', label: 'Corporate' },
];

const NAME_FIELD_VALUE = 'organization.name';
const NAME_FIELD_LABEL = 'Company / Institution Name';
const TYPE_FIELD_VALUE = 'organization.type';

const InputOrganization = (props: propsT) => {
  const [localTypeValue, setLocalTypeValue] = useState<string>(props.type_value || '');
  const [localValidation, setLocalValidation] = useState<boolean>(false); // new form have it true unless error

  let typeError = localValidation ? localTypeValue === '' : props.error;
  function handleTypeChange(value) {
    setLocalTypeValue(value);
    props.onChange(TYPE_FIELD_VALUE, value);
    props.handleBlur();
  }

  // once error is thrown, then we know validation happened in upper component
  // now validate at local component
  useEffect(() => {
    if (props.error) {
      if (!localValidation) setLocalValidation(true);
    }
  }, [props.error]);

  return (
      <CountryFieldContainer>
        <OrganizationTypeSelector>
          {orgTypes.map((item, i) => (
              <OrgType key={'org_type_' + i}>
                <RadioButton
                    key={'org_type_button_' + i}
                    id={'org_type_button_' + i}
                    checked={localTypeValue === item.value}
                    onClick={() => handleTypeChange(item.value)}
                    error={typeError}
                >
                  <RadioLabel
                    key={'org_label_' + i}
                    checked={localTypeValue === item.value}
                    error={typeError}
                  >{item.label}</RadioLabel>
                </RadioButton>
              </OrgType>
            ))}
        </OrganizationTypeSelector>
        <InputText
          key={NAME_FIELD_VALUE + '_' + NAME_FIELD_LABEL}
          type={'text'}
          label={NAME_FIELD_LABEL}
          value={props.name_value}
          onChange={(v) => props.onChange(NAME_FIELD_VALUE, v)}
          error={props.error}
          handleBlur={props.handleBlur}
        />
      </CountryFieldContainer>
  );
};

export default InputOrganization;

const CountryFieldContainer = styled.div`
  padding-top: 3px;
  width: 100%;
`;

const OrganizationTypeSelector = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const OrgType = styled.div`
  display: flex;
  font-size: 12px;
  margin-right: 10px;
`;

const RadioLabel = styled.div<{children?: any, checked: boolean, error?: boolean}>`
  font-size: 14px;
  color: ${(p) => p.error ? '#F44336' : p.checked ? 
        p.theme.palette.textPrimary : p.theme.palette.textSecondary };
`;