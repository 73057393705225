import { useContext } from 'react';
import { StyleContext, THEME } from '@theme/AppStyles';
import MenuButton from '../controls/MenuButton/MenuButton';
import IconSwitch from '../icons/switch.icon';

export const ThemeSwitcher = () => {
  const [style, setStyle] = useContext(StyleContext);

  const toggleTheme = () => {
    if (style === THEME.LIGHT) {
      setStyle(THEME.DARK);
    } else {
      setStyle(THEME.LIGHT);
    }
  };

  return (
    <MenuButton
      onClick={toggleTheme}
      icon={<IconSwitch />}
      spacing={'horizontal'}
      text={'Switch Dark/Light Mode'}
      tooltipPlacement={'bottom-end'}
    />
  );
};
