import React from 'react';
import styled from 'styled-components/macro';
import PanelTitle from '@components/layout/PanelTitle';
import PanelChildren from '@components/layout/PanelChildren';

const PagePanel= (props) => {
  const { title=null, width="100%" } = props;
  return (
    <Panel width={width}>
      { (title !== null) ? <PanelTitle>{title}</PanelTitle> : <></> }
      <PanelChildren>{props.children}</PanelChildren>
    </Panel>
  );
};

export default PagePanel;

const Panel = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  width: calc(${(p) => p.width } - 34px);
  margin: 6px 6px;
  padding: 3px;
  background: ${(p) => p.theme.palette.backgroundSecondary };
  border: 2px solid ${(p) => p.theme.palette.backgroundTertiary };
  border-radius: 15px;
`;
Panel.defaultProps = {
  width: '100%'
}


