import React, {useContext, useState} from 'react';
import styled from 'styled-components/macro';
import { UserContext } from "@contexts/UserContext";

import {userFormFields} from "./forms/config";
import {Row} from "./forms/FormStyles";
import {renderAccountField} from "./utils";
import EditButtons from "./components/EditButtons";

type AccountDetailsProps = {
}

const AccountDetails: React.FC<AccountDetailsProps> = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const handleSave = () => {
    console.log('Account Details | handleSave');
  }

  return(
    <UserEditContainer key={'user-account-edit'}>
      <UserAccountInformation>
        <HeaderRow>
          <Title>User Account Details:</Title>
          <EditButtons
            isEditing = {isEditing}
            setIsEditing={setIsEditing}
            saveFunction={handleSave}
          />
        </HeaderRow>
        <>
          { userFormFields.map((row, index) => (
            <Row width={'100%'} key={'row_' + index}>
              { row.map((f) => renderAccountField(f, index,user.regInfo)) }
            </Row>
          ))}
        </>
      </UserAccountInformation>
    </UserEditContainer>
  )
}
export default AccountDetails;

const Title = styled.div`
  display: flex;
  font-size: 18px;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const UserEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const UserAccountInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentPrimary};
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;