import MenuButton from "@components/controls/MenuButton";
import UploadIcon from "@components/icons/upload.icon";
import CancelIcon from "@components/icons/cancel.icon";
import EditIcon from "@components/icons/edit.icon";
import React from "react";
import styled from "styled-components/macro";

interface EditButtonsProps {
  isEditing : boolean;
  setIsEditing: (arg:boolean) => void;
  saveFunction: () => void;
}

const EditButtons = (props:EditButtonsProps) => {
    return(
      <EditButtonsContainer>
        { props.isEditing ?
          <>
            <MenuButton
              text={'Save Changes'}
              icon={<UploadIcon />}
              margin={'1px'}
              tooltipPlacement='bottom-end'
              onClick={props.saveFunction}
            />
            <MenuButton
              text={'Cancel Changes'}
              icon={<CancelIcon />}
              margin={'1px'}
              tooltipPlacement='bottom-end'
              onClick={()=>props.setIsEditing(false)}
            />
          </> :
          <MenuButton
            text={'Edit My Information'}
            icon={<EditIcon />}
            margin={'1px'}
            tooltipPlacement='bottom-end'
            onClick={()=>props.setIsEditing(true)}
          />
        }
      </EditButtonsContainer>
    )
}

export default EditButtons;

const EditButtonsContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
width: max-content;
`;
