import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const IconInfo = ({size = 24}) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M12,22.8C6.1,22.8,1.2,17.9,1.2,12S6.1,1.2,12,1.2S22.8,6.1,22.8,12S17.9,22.8,12,22.8z M12,2.8c-5.1,0-9.2,4.1-9.2,9.2
	s4.1,9.2,9.2,9.2s9.2-4.1,9.2-9.2S17.1,2.8,12,2.8z"/>
	      <path fill={colorHighlight} d="M11.7,9.8h1.8l-1.4,7.1h-1.8L11.7,9.8z M12.3,7h1.8l-0.3,1.6H12L12.3,7z"/>
      </svg>
    </>
  )
}

export default IconInfo;