import axios from 'axios';

// Application API Definitions
const API_APP_LOCAL:string = 'http://localhost:8000/'
const API_APP_DEV:string = 'https://chem-db-api.thewarriors3.com/'
const API_APP_PRD:string = 'https://chem-db-api.asedasciences.com/'

// Core API Definitions
const API_CORE_LOCAL:string = 'http://localhost:8000/'
const API_CORE_DEV:string = 'https://core-api.thewarriors3.com/'
const API_CORE_PRD:string = 'https://core-api.asedasciences.com/'

// console.log('process.env', process.env);

// interfaces
export interface ResponseT<T> {
  take: number;
  skip: number;
  count: number;
  pages: number;
  data?: T;
}

/* Set up the apiClient endpoints set depending on environment parameter REACT_APP_HOST_TYPE */
export const apiClient_local = axios.create({
  baseURL: API_APP_LOCAL, headers: { 'Content-Type': 'application/json'},
});

export const apiClient_dev = axios.create({
  baseURL: API_APP_DEV, headers: { 'Content-Type': 'application/json'},
});

export const apiClient_prd = axios.create({
  baseURL: API_APP_PRD, headers: { 'Content-Type': 'application/json'},
});

export const apiClient =
  process.env.REACT_APP_API === 'prd'
    ? apiClient_prd : process.env.REACT_APP_API === 'local'
    ? apiClient_local : apiClient_dev;

export const authClient = (token:string) => {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return apiClient;
};


/* Set up the apiCoreClient endpoints set depending on environment parameter REACT_APP_API_CORE */
export const apiCoreClient_local = axios.create({
  baseURL: API_CORE_LOCAL, headers: { 'Content-Type': 'application/json'}
});

export const apiCoreClient_dev = axios.create({
  baseURL: API_CORE_DEV, headers: { 'Content-Type': 'application/json'},
});

export const apiCoreClient_prd = axios.create({
  baseURL: API_CORE_PRD, headers: {'Content-Type': 'application/json'},
});

export const apiCoreClient =
  process.env.REACT_APP_API_CORE === 'local'
    ? apiCoreClient_prd : process.env.REACT_APP_API_CORE === 'local'
    ? apiCoreClient_local : apiCoreClient_dev;

export const authCoreClient = (token:string) => {
  apiCoreClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return apiCoreClient;
};
