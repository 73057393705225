// @ts-ignore
import spinner_img from './spinner.png';

const Spinner = () => {
  const width=24;
  return (
    <>
      <img
        src={spinner_img}
        style={{ width: width, margin: 'auto', display: 'block' }}
        alt='Loading...'
      />
    </>
  );
};

export default Spinner;
