import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const IconDataExplorer = ({size = 24}) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M23.7,8.9c-0.1-1.3-1.2-2.4-2.5-2.4H18c-0.1-1.3-1.2-2.3-2.5-2.3H12H2.8c-1.4,0-2.6,1.1-2.6,2.6v10.4c0,1.4,1.1,2.6,2.6,2.6
	H4v-1.5H2.8c-0.6,0-1.1-0.5-1.1-1.1V6.8c0-0.6,0.5-1.1,1.1-1.1H7c0.1,1.3,1.2,2.3,2.5,2.3h6.7v0h4.9c0.6,0,1.1,0.5,1.1,1.1v0.3v7.7
	c0,0.6-0.5,1.1-1.1,1.1H20v1.5h1.2c1.4,0,2.6-1.1,2.6-2.6V9.5V9.1L23.7,8.9L23.7,8.9z M8.6,5.8H12h3.4c0.5,0,0.9,0.4,1,0.8H12v0H9.6
	C9.1,6.6,8.7,6.2,8.6,5.8z"/>
        <path fill={colorHighlight} d="M11.3,13.7h1.5c0.7,0,1.2-0.5,1.2-1.2V11c0-0.7-0.5-1.2-1.2-1.2h-1.5c-0.7,0-1.2,0.5-1.2,1.2v1.5
	C10.1,13.2,10.7,13.7,11.3,13.7z M10.9,11c0-0.3,0.2-0.5,0.5-0.5h1.5c0.3,0,0.5,0.2,0.5,0.5v1.5c0,0.3-0.2,0.5-0.5,0.5h-1.5
	c-0.3,0-0.5-0.2-0.5-0.5V11z"/>
        <path fill={colorHighlight} d="M18,17.8h-0.3c0.1-0.1,0.1-0.3,0.1-0.5v-1.2c0-0.7-0.5-1.2-1.2-1.2h-4.1v-0.7h-0.8v0.7H7.4
	c-0.7,0-1.2,0.5-1.2,1.2v1.2c0,0.2,0,0.3,0.1,0.5H6.2C5.5,17.8,5,18.3,5,19v1.5c0,0.7,0.5,1.2,1.2,1.2h1.5c0.7,0,1.2-0.5,1.2-1.2V19
	c0-0.7-0.5-1.2-1.2-1.2H7.6H7.4c-0.3,0-0.5-0.2-0.5-0.5v-1.2c0-0.3,0.2-0.5,0.5-0.5h4.3v2.1h-0.4c-0.7,0-1.2,0.5-1.2,1.2v1.5
	c0,0.7,0.5,1.2,1.2,1.2h1.5c0.7,0,1.2-0.5,1.2-1.2V19c0-0.7-0.5-1.2-1.2-1.2h-0.4v-2.1h4.1c0.3,0,0.5,0.2,0.5,0.5v1.2
	c0,0.3-0.2,0.5-0.5,0.5h-0.1h-0.1v0c-0.6,0.1-1.1,0.6-1.1,1.2v1.5c0,0.7,0.5,1.2,1.2,1.2H18c0.7,0,1.2-0.5,1.2-1.2V19
	C19.2,18.3,18.7,17.8,18,17.8z M7.6,18.5L7.6,18.5c0.3,0,0.5,0.2,0.5,0.5v1.5C8.2,20.8,8,21,7.7,21H6.2c-0.3,0-0.5-0.2-0.5-0.5V19
	c0-0.3,0.2-0.5,0.5-0.5h1.2H7.6z M13.3,19v1.5c0,0.3-0.2,0.5-0.5,0.5h-1.5c-0.3,0-0.5-0.2-0.5-0.5V19c0-0.3,0.2-0.5,0.5-0.5h1.5
	C13.1,18.5,13.3,18.7,13.3,19z M18.5,20.5c0,0.3-0.2,0.5-0.5,0.5h-1.5c-0.3,0-0.5-0.2-0.5-0.5V19c0-0.3,0.2-0.5,0.5-0.5h0.1H18
	c0.3,0,0.5,0.2,0.5,0.5V20.5z"/>
      </svg>
    </>
  )
}

export default IconDataExplorer;