import React from 'react';
import styled from 'styled-components/macro';

const Info = () => {
  return(
    <InfoContainer>
      <InfoTitle>Overview of the FAERS Analysis Process</InfoTitle>
      <InfoMessage>[ ....... Add overview information here .....]</InfoMessage>
    </InfoContainer>);
}

export default Info;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const InfoTitle = styled.div`
  display: flex;
  width: 100%;
  font-size: 20px;
  margin-bottom: 10px;
`;

const InfoMessage = styled.div`
  display: flex;
  padding-left: 20px;
  color: ${(p) => p.theme.palette.textSecondary};
  width: 100%;
`;